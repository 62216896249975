<page
  [detailData]="{title: pageTitle, status: subscription?.status}"
  [firewalled]="firewalled"
  [productType]="productType"
  [terminated]="isTerminated"
>
  <div class="container" *ngIf="subscription">
    <div class="row">
      <div class="col-lg-3 col--flex">
        <shortcut-menu
          *ngIf="!isTerminated && subscription"
          [subscription]="subscription"
          [productType]="productType"
          [messages]="activities"
        ></shortcut-menu>
        <terminated-card *ngIf="isTerminated" [date]="subscription.endDate * 1000 | date:'y-MM-dd hh:mm'">
        </terminated-card>
      </div>

      <div class="col-lg-9">
        <div class="card">
          <subscription-detail-header [subscription]="subscription" [isTerminated]="isTerminated" />

          <div class="row">
            <div class="col-12 col-sm-7">
              <table class="table-properties table-properties--no-margin">
                <tr>
                  <td>{{ 'ServiceElement.Owner' | translate }}</td>
                  <td>{{ subscription.organisation }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.OwnAlias' | translate }}</td>
                  <td>
                    <span class="alias" *ngIf="!isEditable">
                      {{ subscription.customerDescription }}
                      <div class="alias__icon" (click)="startEditingCustomerDescription()">
                        <svg-icon src="assets/images/icons/edit.svg"></svg-icon>
                      </div>
                    </span>
                    <div *ngIf="isEditable" class="alias__form">
                      <input
                        [(ngModel)]="temporarySubscriptionName"
                        class="input"
                        type="text"
                        value="{{ subscription.customerDescription }}"
                        autofocus
                      />
                      <span class="alias__form-button alias__form-button--save" (click)="saveCustomerDescription()">
                        <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
                      </span>
                      <span
                        class="alias__form-button alias__form-button--cancel"
                        (click)="cancelEditingCustomerDescription()"
                      >
                        <svg-icon src="assets/images/icons/x.svg"></svg-icon>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.SURFnetDescription' | translate }}</td>
                  <td>{{ subscription.description }}</td>
                </tr>
                <tr
                  third-party-service-ids-row-content
                  *ngIf="subscription.references?.length"
                  [subscription]="subscription"
                ></tr>
                <tr>
                  <td>{{ 'ServiceElement.ServiceID' | translate }}</td>
                  <td>
                    <span class="clipboard__rule" *ngIf="subscription.subscriptionId"
                      >{{ subscription.subscriptionId | shorten_guid }}
                      <span class="clipboard__link" ngxClipboard [cbContent]="subscription.subscriptionId">
                        <div class="clipboard__icon">
                          <svg-icon src="assets/images/icons/copy.svg"></svg-icon>
                        </div>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.ProductName' | translate }}</td>
                  <td>Internet (aka SURFinternet)</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.ServiceSpeed' | translate }}</td>
                  <td>{{ subscription.serviceSpeed * 1000000 | readable_size }}</td>
                </tr>
              </table>
            </div>
            <div class="col-12 col-sm-5">
              <table class="table-properties table-properties--no-margin">
                <tr *ngIf="subscription.ipRoutingType === 'BGP'">
                  <td>{{ 'ServiceElement.ASNnummer' | translate }}</td>
                  <td>{{ subscription.ipss.asn }}</td>
                </tr>
                <tr *ngIf="subscription.ipRoutingType === 'BGP'">
                  <td>{{ 'ServiceElement.SURFASNnummer' | translate }}</td>
                  <td>1103</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.SURFcertfilter' | translate }}</td>
                  <td *ngIf="subscription">
                    <span
                      *ngIf="auth?.isCurrentOrganisation(subscription.customerId) && auth?.hasRole(['Infraverantwoordelijke', 'surfcert-kernel', 'Beveiligingsverantwoordelijke']) && !isTerminated && !subscription.firewallEnabled; else readOnly"
                      class="table-properties__flex"
                      [ngClass]="{'table-properties__flex--blue': subscription.ipss?.surfcertFilter === 'enabled', 'table-properties__flex--disabled': false }"
                    >
                      {{ subscription.ipss?.surfcertFilter | enabled_or_value }}
                      <selfservice-toggle
                        [active]="subscription.ipss?.surfcertFilter === 'enabled'"
                        [tooltipText]="'Selfservice.Tooltip.DDos' | translate"
                        (click)="openSelfServiceDialog('ddos')"
                      ></selfservice-toggle>
                    </span>
                    <ng-template #readOnly> {{ subscription.ipss?.surfcertFilter | enabled_or_value }} </ng-template>
                  </td>
                </tr>
                <tr *ngIf="isTerminated">
                  <td>{{ 'ServiceElement.Status' | translate }}</td>
                  <td>{{ subscription.status }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.Start' | translate }}</td>
                  <td>{{ subscription.startDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
                </tr>
                <tr *ngIf="isTerminated">
                  <td>{{ 'ServiceElement.End' | translate }}</td>
                  <td>{{ subscription.endDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.Domain' | translate }}</td>
                  <td>{{ subscription.domain }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.RouteringType' | translate }}</td>
                  <td>{{ subscription.ipRoutingType }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isTerminated" class="hide-everything-after-this-div"></div>

    <page-tab [productType]="productType" (activeTabEmitter)="changeActiveTab($event)"> </page-tab>

    <div *ngIf="activeTab === 'config'">
      <div class="section">
        <tabbed-detail-container
          [service]="subscription"
          [bus]="messageBus"
          [canModify]="productType !== 'FW'"
          [selfserviceState]="undefined"
        />
      </div>

      <linechart id="chart" #chart [subscription]="subscription" />
    </div>

    <div *ngIf="activeTab === 'graphic-view'">
      <networkgraph [topology]="subscription.topology" #chart></networkgraph>
    </div>
  </div>
</page>
