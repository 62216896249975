import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { Apollo, ApolloBase } from "apollo-angular";
import { CERT_MITIGATION_PREFIXES_QUERY } from "../../../graphql/replication/cert-mitigation";
import { AuthService } from "../../../services/AuthService";
import { DialogAuthComponent } from "../../dialogs/dialog-auth/dialog-auth";
import { LoadingState } from "../../../services/loading-state";
import { IpPrefixDdosType, IpPrefixDdosTypeConnectionV2 } from "../../../gql/generated-ingestor";
import { openSelfServiceDialog } from "../../dialogs/dialog-selfservice/dialog-selfservice.helpers";

@Component({
  selector: "cert-mitigation",
  templateUrl: "cert-mitigation.html",
  styleUrls: ["cert-mitigation.scss"],
})
export class CertMitigationComponent implements OnInit {
  public subscriptions: IpPrefixDdosType[] = [];
  public mitigationState: any = {};
  public loading = false;
  private sort: Sort = {
    active: "description",
    direction: "asc",
  };
  private pendingAction = false;
  private pendingActionSubscriptionId = "";
  private replicatedData: ApolloBase;

  constructor(
    public auth: AuthService,
    private dialog: MatDialog,
    public loadingState: LoadingState,
    private apolloProvider: Apollo,
  ) {
    this.replicatedData = this.apolloProvider.use("replicatedData");
  }

  get sortedData(): IpPrefixDdosType[] {
    return this.sortData(this.sort);
  }

  refreshSubscriptions() {
    this.replicatedData
      .query<{ ipPrefixDdos: IpPrefixDdosTypeConnectionV2 }>({
        query: CERT_MITIGATION_PREFIXES_QUERY,
        variables: { customer: localStorage.getItem("viewingCustomerGUID") },
      })
      .subscribe(({ data }) => {
        this.subscriptions = data.ipPrefixDdos.page;
        this.loadingState.total = this.subscriptions.length;
      });
  }

  ngOnInit() {
    this.handlePendingAction();
    this.refreshSubscriptions();
  }

  /**
   * First: check if there is a pending strong action for auto mitigation.
   * If so, set the flag and store the subscriptionID.
   *
   */
  handlePendingAction() {
    if (this.auth.hasPendingStrongAction("autoMitigation")) {
      const state = this.auth.getSelfserviceState();
      if (state) {
        this.pendingActionSubscriptionId = state.subscription?.subscriptionId;
        this.auth.clearPendingStrongAction("autoMitigation");
        this.pendingAction = true;
      }
    }
  }

  /**
   * Called after the automitigation state is fetched (see getMitigationStatus()).
   *
   * @param subscriptionId the subscription ID
   */
  startPendingAction(subscriptionId: string) {
    const subscription = this.subscriptions.find((s) => s.subscriptionId === subscriptionId);
    this.toggleClick(subscription);
    this.pendingAction = false; // reset flag to prevent another dialog after submitting
  }

  /**
   * Used in templatee: extract prefix part from CIDR notation
   *
   * @param prefix Prefix in CIDR notation
   * @returns prefix size
   */
  prefixFor(prefix?: string): string {
    if (!prefix) {
      return "-";
    }
    const m = prefix.match(/(\/[0-9]{2,3})$/);
    return m ? m[1] : "-";
  }

  addressFor(prefix?: string): string {
    if (!prefix) {
      return "-";
    }
    const pos = prefix.indexOf("/");
    if (pos < 0) {
      return "";
    }
    return prefix.substring(0, pos);
  }

  ipVersionFor(afi?: number): string {
    if (!afi) {
      return "-";
    }
    switch (afi) {
      case 4:
        return "IPv4";
      case 6:
        return "IPv6";
      default:
        return "";
    }
  }

  changeSort(sort: Sort): void {
    this.sort = sort;
  }

  sortData(sort: Sort): IpPrefixDdosType[] {
    if (!sort.active || sort.direction === "") {
      return this.subscriptions;
    }

    // Make a new list sorting so the original subscription is not affected (e.g. pretend to be immutable)
    let subscriptions = [...this.subscriptions];
    return subscriptions.slice().sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "description":
          return this.compare(a.description, b.description, isAsc);
        case "prefix":
          // TODO: share this prefix sorting with the code in ip-prefixes
          let prefixA = a.prefix;
          let prefixB = b.prefix;
          if (prefixA === undefined) {
            prefixA = "";
          }
          if (prefixB === undefined) {
            prefixB = "";
          }
          // convert : and / to .
          prefixA = prefixA.replace(":", ".").replace("/", ".");
          prefixB = prefixB.replace(":", ".").replace("/", ".");

          prefixA = prefixA
            .split(".")
            .map((num) => num.padStart(3, "0"))
            .join("");
          prefixB = prefixB
            .split(".")
            .map((num) => num.padStart(3, "0"))
            .join("");

          // remove non numeric data
          prefixA = prefixA.replace(/\D/g, "");
          prefixB = prefixB.replace(/\D/g, "");

          return this.compare(Number(prefixA), Number(prefixB), isAsc);
        case "version":
          const versionA = a.version;
          const versionB = b.version;
          return this.compare(versionA, versionB, isAsc);

        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  toggleClick(subscription: IpPrefixDdosType) {
    // update automitigation
    this.openSelfserviceDialog(subscription);
  }

  openSelfserviceDialog(subscription: IpPrefixDdosType) {
    const roleCheckResult = this.auth.checkRoleAccess("cert", "edit");
    if (!roleCheckResult.ok) {
      this.auth.roleEvent.emit(roleCheckResult);
      return;
    }

    if (!this.auth.isAuthenticatedForSelfService()) {
      const data = { selfserviceState: { subscription }, initialAction: "autoMitigation" };
      this.dialog.open(DialogAuthComponent, { data });
    } else {
      const type = "autoMitigation";
      const mitigationState: "enabled" | "disabled" = subscription.autoMitigation ? "enabled" : "disabled";
      const product: { type: "IpPrefixDdos"; tag: "" } = { type: "IpPrefixDdos", tag: "" };
      const subscriptionWithProduct = { ...subscription, product };

      const data = { type, subscription: subscriptionWithProduct, mitigationState };
      const dialogRef = openSelfServiceDialog(this.dialog, data);
      dialogRef.componentInstance.close.subscribe((event: string) => {
        if (event === "refresh") {
          const subscriptionId = subscription.subscriptionId;
          this.refreshSubscriptions();

          if (this.pendingAction && subscriptionId === this.pendingActionSubscriptionId) {
            setTimeout(() => this.startPendingAction(subscriptionId), 200);
          }
        }
      });
    }
  }
}
