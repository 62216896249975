import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { LightpathSubscriptionDetailsGQL, LightpathSubscriptionDetailsQuery } from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { LightPathSubscriptionDetails } from "../../types";
import { ApolloQueryResult } from "@apollo/client";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "lightpath-subscription",
  templateUrl: "lightpath-subscription.html",
})
export class LightpathSubscriptionComponent extends GenericSubscriptionComponent<
  LightpathSubscriptionDetailsGQL,
  LightpathSubscriptionDetailsQuery,
  LightPathSubscriptionDetails
> {
  constructor(
    protected api: ApiHelper,
    protected auth: AuthService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected query: LightpathSubscriptionDetailsGQL,
  ) {
    super(api, auth, dialog, route);
  }

  get allPortsAreUntagged(): boolean {
    return this.subscription?.circuits?.every((circuit) =>
      circuit.endpoints.every((endpoint) => endpoint.port.portMode === "Untagged"),
    );
  }

  get allPortsAreNotAggregated(): boolean {
    return this.subscription?.circuits?.every((circuit) =>
      circuit.endpoints.every((endpoint) => endpoint.port.product.tag !== "AGGSP"),
    );
  }

  protected onQuerySuccess(result: ApolloQueryResult<LightpathSubscriptionDetailsQuery>): LightPathSubscriptionDetails {
    return result.data.lpDetails;
  }
}
