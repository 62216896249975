import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { GenericSubscriptionComponent } from "../generic-subscription";
import {
  PortSubscriptionDetailsGQL,
  PortSubscriptionDetailsQuery,
  ServicePortSubscriptionDetailsType,
} from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { ApolloQueryResult } from "@apollo/client";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "port-subscription",
  templateUrl: "port-subscription.html",
})
export class PortSubscriptionComponent extends GenericSubscriptionComponent<
  PortSubscriptionDetailsGQL,
  PortSubscriptionDetailsQuery,
  ServicePortSubscriptionDetailsType
> {
  // possibly move these to a superclass for these components?
  public impactSetting = "Never";
  public linkPortSubscription = false; // customer can view the linked port subscription?
  public portSubscriptionName = "Port subscription name";

  constructor(
    protected api: ApiHelper,
    protected auth: AuthService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected query: PortSubscriptionDetailsGQL,
  ) {
    super(api, auth, dialog, route);
  }

  protected onQuerySuccess(
    result: ApolloQueryResult<PortSubscriptionDetailsQuery>,
  ): ServicePortSubscriptionDetailsType {
    return result.data.servicePortDetails as ServicePortSubscriptionDetailsType;
  }
}
