import { Component, Input, ViewContainerRef, OnInit, OnChanges } from "@angular/core";
import { MatomoTracker } from "ngx-matomo";
import { AuthService } from "../../../services/AuthService";
import { ApiHelper } from "../../../helpers/apihelper";
import { MatDialog } from "@angular/material/dialog";
import { ServiceElement } from "../service-element/service-element";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { SubscriptionDetails } from "../../../helpers/types";
import { DialogIncidentComponent } from "../../dialogs/dialog-incident/dialog-incident";
import { Instance } from "../../models/instance";
import { knownVendors } from "../../../helpers/kpi/kpi-item";
import { getDetailRouterLink } from "../../../helpers/getDetailRouterLink";

@Component({
  selector: "service-element-simple",
  templateUrl: "service-element-simple.html",
})
export class ServiceElementSimple extends ServiceElement implements OnInit, OnChanges {
  @Input() subscription: Instance;
  @Input() productType: string;

  public hideLoader = false;
  public temporaryName: string;
  public isEditable = false;
  public wirelessKpiList: {
    activeAps: number | null;
    inactiveAps: number | null;
    kpiAverages: { [key: string]: number }[];
  };
  public subscriptionStatus: string;
  private customerGuid: string;

  constructor(
    public auth: AuthService,
    public dialog: MatDialog,
    public api: ApiHelper,
    private subscriptionService: SubscriptionService,
    public viewContainerRef: ViewContainerRef,
    public matomo: MatomoTracker,
  ) {
    super(auth, dialog, api, viewContainerRef, matomo);
  }

  get wirelessVendor(): string | undefined {
    const serviceTags: string[] = (this.subscription.tags ?? []).map((tag) => tag.toLowerCase());
    const wirelessVendor: string | undefined = serviceTags.filter((tag) => knownVendors.includes(tag)).at(0);
    return wirelessVendor;
  }

  ngOnInit() {
    this.customerGuid = localStorage.getItem("viewingCustomerGUID");
  }

  ngOnChanges() {
    // have not fetched health before, but we have a subscription
    if (!this.subscription?.health && this.subscription?.subscriptionId) {
      this.loadHealth();
    }

    if (this.productType === "Wireless") {
      this.subscriptionService.getWirelessListKpiForSubscription(this.subscription.subscriptionId).then((kpiList) => {
        this.wirelessKpiList = kpiList;

        this.wirelessKpiList.activeAps = this.subscription?.location.activeAccessPoints;
        this.wirelessKpiList.inactiveAps = this.subscription?.location.inactiveAccessPoints;
      });
    }
  }

  loadHealth() {
    this.subscriptionService.getHealthDataForSubscription(this.subscription.subscriptionId).then((healthData) => {
      this.subscription.health = healthData;
    });
  }

  edit() {
    event.stopPropagation();
    this.startEditingCustomerDescription();
  }

  stop() {
    event.stopPropagation();
  }

  startEditingCustomerDescription() {
    if (this.subscription.customerDescriptions === null) {
      this.subscription.customerDescriptions = {};
    }
    this.temporaryName = this.subscription.presentableName;
    this.isEditable = true;
  }

  cancelEditingCustomerDescription(event) {
    event.stopPropagation();
    this.isEditable = false;
    this.temporaryName = this.subscription.presentableName;
  }

  saveCustomerDescription() {
    event.stopPropagation();
    if (this.temporaryName.length > 0) {
      this.api
        .set_customer_description(this.customerGuid, this.subscription.subscriptionId, this.temporaryName)
        .then((result) => {
          this.isEditable = false;
          this.subscription.updatePresentableName(this.temporaryName);
        })
        .catch((err) => {
          console.log(err);
          this.isEditable = false;
        });
    }
  }

  openIncidentDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(DialogIncidentComponent, {
      data: {
        subscription: this.subscription,
      },
    });
    return false;
  }

  getRouterLink() {
    return getDetailRouterLink(this.subscription, this.productType);
  }

  private configureMeta(meta: SubscriptionDetails) {
    if (!meta) {
      return;
    }

    // TODO Modify all subscription lists of the below types to have an endpoints key property of the below shape
    // Leaving the below code in for now as documentation for what needs to be included
    let endpoints = [];
    if (meta.circuits && meta.circuits[0]) {
      if (this.productType === "L3VPN") {
        endpoints = meta.l3vpnsapss.map((l3vpnsaps) => {
          if (l3vpnsaps.sap.port.address !== null) {
            return {
              street: l3vpnsaps.sap.port.address?.street ?? "-",
              number: l3vpnsaps.sap.port.address?.number ?? "-",
              city: l3vpnsaps.sap.port.address?.city,
            };
          }
          return false;
        });
      } else if (this.productType === "IP") {
        endpoints = meta.saps.map((sap) => ({
          street: sap.port.address?.street ?? "-",
          number: sap.port.address?.number ?? "-",
          city: sap.port.address?.city,
        }));
      } else {
        endpoints = meta.circuits[0].endpoints.map((item) => ({
          street: item.port.address?.street ?? "-",
          number: item.port.address?.number ?? "-",
          city: item.port.address?.city,
        }));
      }
    }
    if (this.productType === "Port") {
      endpoints = [
        {
          street: meta.address?.street,
          number: meta.address?.number,
          city: meta.address?.city,
        },
      ];
    }
    this.subscription.meta = meta;
  }
}
