import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import {
  WirelessHealthDetail,
  WirelessSubscriptionDetailsGQL,
  WirelessSubscriptionDetailsQuery,
} from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { WirelessSubscriptionDetails } from "../../types";
import { SubscriptionService } from "../../../../services/subscriptionservice";
import { ApolloQueryResult } from "@apollo/client";
import { MatDialog } from "@angular/material/dialog";
import { ChartChoice, WIRELESS_CHART_CHOICES } from "../../../../components/subscription-detail/linechart/support";

@Component({
  selector: "wireless-subscription",
  templateUrl: "wireless-subscription.html",
})
export class WirelessSubscriptionComponent extends GenericSubscriptionComponent<
  WirelessSubscriptionDetailsGQL,
  WirelessSubscriptionDetailsQuery,
  WirelessSubscriptionDetails
> {
  public impactSetting = "Never";
  public healthData: WirelessHealthDetail | undefined = undefined;
  public chartChoices: ChartChoice[] = [];

  constructor(
    protected api: ApiHelper,
    protected auth: AuthService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected query: WirelessSubscriptionDetailsGQL,
    private subscriptionService: SubscriptionService,
  ) {
    super(api, auth, dialog, route);
    this.productType = "Wireless";
    this.productTag = "";
    this.subscriptionChange.subscribe((s) => this.onSubscriptionChange(s));
  }

  protected onQuerySuccess(result: ApolloQueryResult<WirelessSubscriptionDetailsQuery>): WirelessSubscriptionDetails {
    return result.data.wirelessDetails;
  }

  private onSubscriptionChange(subscription: WirelessSubscriptionDetails) {
    this.chartChoices = WIRELESS_CHART_CHOICES?.[subscription.supplier.toUpperCase()] ?? [];

    this.subscriptionService
      .getWirelessHealthDataForSubscription(subscription.subscriptionId)
      .catch((reason) => console.warn("Failed to retrieve wireless health data:", reason))
      // @ts-ignore
      .then((healthData) => (this.healthData = healthData));
  }
}
