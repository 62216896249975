import { TypedDocumentNode, gql } from "apollo-angular";
import { ProductType } from "../../helpers/enums/productType";

const fragmentBaseSubscriptionList = `
  subscriptionId
  description
  tags
  status
  customerDescription

  customer {
    customerId
    fullname
  }
  product {
    productId: id
    type
    tag
  }
`;

const fragmentAddress = `
  address {
    city
    number
    street
    addition
  }
`;

const fragmentLocations = `
  locations {
    ${fragmentAddress}
  }
`;

const SERVICE_TYPE = gql`
  fragment ServiceTypeFragment on ServiceType {
    subscriptionId
    product {
      type
      tag
    }
    speed
    percentage
    description
  }
`;

const PORT_SERVICE_TYPE = gql`
  fragment PortServiceTypeFragment on PortServiceType {
    subscriptionId
    productType
    speed
    percentage
    description
  }
`;

const fragmentPageInfo = `
  pageInfo {
    totalItems
  }
`;

export const PORT_SUBSCRIPTIONS_OF_TYPE_QUERY_2 = gql`
${SERVICE_TYPE}
  query PortListQuery($customer: UUID!) {
    subscriptions: portList(sort: {subscriptionId: ASC}, searchFilter: { accessCustomerId: $customer }) {
      page {
        ${fragmentBaseSubscriptionList}
        portSpeed
        portMode
        speed
        amountReserved
        services {
           ...ServiceTypeFragment
        }

        ${fragmentLocations}
      }
      ${fragmentPageInfo}
    }
  }
`;

export const PORT_SUBSCRIPTIONS_OF_CUSTOMER_QUERY = gql`
${SERVICE_TYPE}
  query PortListQuery($customer: UUID!) {
    subscriptions: portList(sort: {subscriptionId: ASC}, searchFilter: { customerId: $customer }) {
      page {
        ${fragmentBaseSubscriptionList}
        portSpeed
        portMode
        speed
        amountReserved
        usedVlans
        services {
           ...ServiceTypeFragment
        }
        address {
          city
          street
        }
      }
      ${fragmentPageInfo}
    }
  }
`;

export const L3VPN_SUBSCRIPTIONS_OF_TYPE_QUERY = gql`
  query L3vpnListQuery($customer: UUID!) {
    subscriptions: l3vpnList(sort: {subscriptionId: ASC}, searchFilter: { accessCustomerId: $customer }) {
      page {
        __typename
        ${fragmentBaseSubscriptionList}
        ${fragmentLocations}
      }
      ${fragmentPageInfo}
    }
  }
`;

export const L2VPN_SUBSCRIPTIONS_OF_TYPE_QUERY = gql`
  query L2vpnListQuery($customer: UUID!) {
    subscriptions: l2vpnList(sort: {subscriptionId: ASC}, searchFilter: { accessCustomerId: $customer }) {
      page {
        __typename
        ${fragmentBaseSubscriptionList}
        ${fragmentLocations}
      }
      ${fragmentPageInfo}
    }
  }
`;

export const LP_SUBSCRIPTIONS_OF_TYPE_QUERY = gql`
${PORT_SERVICE_TYPE}
  query LightPathListQuery($customer: UUID!) {
    subscriptions: evpnPtpList(sort: {subscriptionId: ASC}, searchFilter: { accessCustomerId: $customer }) {
      page {
        __typename
        ${fragmentBaseSubscriptionList}
        ${fragmentLocations}
        services {
          ...PortServiceTypeFragment
        }
      }
      ${fragmentPageInfo}
    }
  }
`;

export const IP_PREFIXES_LIST_QUERY = gql`
  query IpPrefixesListQuery($customer: UUID!) {
    ipPrefixList(sort: { subscriptionId: ASC }, searchFilter: { accessCustomerId: $customer }) {
      page {
        subscriptionType: __typename
        subscriptionId
        customerId
        description
        startDate
        customerDescription
        insync

        ipPrefix {
          afi
          state
          prefix
        }

        ipSubscriptions {
          subscriptionId
          customerId
          description
          customerDescription
          product {
            type
            tag
          }
        }

        availableIpSubscriptions {
          subscriptionId
          customerId
          description
          customerDescription
          product {
            type
            tag
          }
        }
      }
    }
  }
`;

export const WIRELESS_SUBSCRIPTIONS_OF_TYPE_QUERY = gql`
  query WirelessListQuery($customer: UUID!) {
    subscriptions: wirelessList(sort: {subscriptionId: ASC}, searchFilter: { accessCustomerId: $customer }) {
      page {
        __typename
        ${fragmentBaseSubscriptionList}
        name
        supplier
        sensorPresent
        location {
          activeAccessPoints
          inactiveAccessPoints
        }
      }
      ${fragmentPageInfo}
    }
  }
`;

export const IP_SUBSCRIPTIONS_OF_TYPE_QUERY = gql`
  query IpListQuery($customer: UUID!) {
    subscriptions: ipList(sort: {subscriptionId: ASC}, searchFilter: { accessCustomerId: $customer }) {
      page {
        ${fragmentBaseSubscriptionList}
        ${fragmentLocations}
        protection
      }
      ${fragmentPageInfo}
    }
  }
`;

export const FIREWALL_SUBSCRIPTIONS_OF_TYPE_QUERY = gql`
  query FirewallListQuery($customer: UUID!) {
    subscriptions: firewallList(sort: {subscriptionId: ASC}, searchFilter: { accessCustomerId: $customer }) {
      page {
        ${fragmentBaseSubscriptionList}
      }
      ${fragmentPageInfo}
    }
  }
`;

export const LIST_QUERIES: { [key: string]: TypedDocumentNode<any, any> } = {
  [ProductType.Firewall]: FIREWALL_SUBSCRIPTIONS_OF_TYPE_QUERY,
  [ProductType.IP]: IP_SUBSCRIPTIONS_OF_TYPE_QUERY,
  [ProductType.IPPrefixes]: IP_PREFIXES_LIST_QUERY,
  [ProductType.L3VPN]: L3VPN_SUBSCRIPTIONS_OF_TYPE_QUERY,
  [ProductType.L2VPN]: L2VPN_SUBSCRIPTIONS_OF_TYPE_QUERY,
  [ProductType.LightPath]: LP_SUBSCRIPTIONS_OF_TYPE_QUERY,
  [ProductType.Port]: PORT_SUBSCRIPTIONS_OF_TYPE_QUERY_2,
  [ProductType.Wireless]: WIRELESS_SUBSCRIPTIONS_OF_TYPE_QUERY,
};
