import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { StorageHelper } from "../../../helpers/storage/storagehelper";
import { ActivatedRoute } from "@angular/router";
import { SelfserviceDialogType } from "../../../helpers/self-service/models/types";
import * as Apollo from "apollo-angular";
import { ApolloQueryResult } from "@apollo/client";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CimQueryImpactTypes, CimQueryStatuses } from "../../../graphql/custom-types";
import dayjs from "dayjs";
import { CIM_SUBSCRIPTION } from "../../../graphql/domain/cim";
import { ENV } from "../../../app/app.runtime";
import { AuthService } from "../../../services/AuthService";
import { DialogAuthComponent } from "../../../components/dialogs/dialog-auth/dialog-auth";
import { Subscription as RxSubscription } from "rxjs";
import { openSelfServiceDialog } from "../../../components/dialogs/dialog-selfservice/dialog-selfservice.helpers";

// prettier-ignore
@Component({ template: "" })
export abstract class GenericSubscriptionComponent<
  Q extends Apollo.Query<R, { subscriptionId: string, accessCustomerId: string }>,
  R,
  S extends MinimalSubscription,
> implements OnDestroy, OnInit {
  @Output() incident: EventEmitter<string> = new EventEmitter();
  @Output() notification: EventEmitter<string> = new EventEmitter();

  public impactSetting = "Never";
  public activeTab = "config";
  public activities: Notification[] = [];

  public dialogRef: MatDialogRef<MatDialog>; // TODO test

  public firewalled = false;

  public isEditable = false;
  public isTerminated: boolean;

  public messageBus: EventEmitter<string> = new EventEmitter();

  public temporarySubscriptionName = "";

  public pageTitle = "";
  public productType = "";
  public productTag = "";

  public subscriptionId = "";
  public selfserviceDialogTypes: SelfserviceDialogType[] = [];

  public subscriptionChange: EventEmitter<S | undefined> = new EventEmitter();
  protected query: Q | undefined = undefined;
  private _subscription: S | undefined = undefined;

  private rxSubscriptions: RxSubscription[] = [];

  constructor(
    protected api: ApiHelper,
    protected auth: AuthService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
  ) {
    this.subscriptionId = this.route.snapshot.paramMap.get("subscriptionId");
  }

  public get subscription(): S | undefined {
    return this._subscription;
  }

  public set subscription(subscription: S) {
    this.isTerminated = subscription?.status === "terminated"
    this._subscription = subscription;
    this.pageTitle = subscription.customerDescription;
    this.productType = subscription.product.type;
    this.productTag = subscription.product.tag;
    if (!ENV.REPLICATION_ENABLED) {
      // nglint:disable-next-line:no-console
      console.warn(`Accessing replicated ${this.productType + (this.productTag ? `:${this.productTag}` : "")} detail page`);
    }
    this.firewalled = subscription.firewallEnabled ?? false;
    this.subscriptionChange.emit(this._subscription);
  }

  ngOnInit() {
    const initSubscription = this.auth.userLoaded.subscribe((loaded) => loaded ? this.refresh() : undefined);
    const refreshSubscription = this.messageBus.subscribe((msg) => msg === "refresh" ? this.refresh() : undefined);
    this.rxSubscriptions.push(initSubscription);
    this.rxSubscriptions.push(refreshSubscription);

    if (!this.auth.state.loading || this.auth.viewingCustomerId) {
      this.refresh();
    }
  }

  ngOnDestroy() {
    while (this.rxSubscriptions.length) {
      this.rxSubscriptions.pop().unsubscribe();
    }
  }

  /** Notifications **/
  fetchActivities() {
    const endDate = dayjs().add(2, "week").toDate();
    const subscription = this.api.apollo
      .query<{ notifications: Array<Notification> }>({
        query: CIM_SUBSCRIPTION,
        fetchPolicy: "network-only",
        variables: {
          filter: {
            customerId: localStorage.getItem("viewingCustomerGUID"),
            beginTimestamp: new Date(),
            endTimestamp: endDate,
            statuses: [CimQueryStatuses.OPEN, CimQueryStatuses.UPDATED],
            impacts: [
              CimQueryImpactTypes.DOWN,
              CimQueryImpactTypes.REDUCED_REDUNDANCY,
              CimQueryImpactTypes.RESILIENCE_LOSS,
            ],
            subscriptionIds: [this.subscriptionId],
          },
        },
      })
      .subscribe((data) => {
        // TODO #206 Address technical debt Notifications/Messages/PlannedWorks
        this.activities = data.data.notifications;
      });
    this.rxSubscriptions.push(subscription);
  }

  /** Customer description interaction **/
  async startEditingCustomerDescription() {
    this.temporarySubscriptionName = this.subscription.customerDescription;
    this.isEditable = true;
  }

  async saveCustomerDescription() {
    if (this.temporarySubscriptionName.length > 0) {
      this.api
        .set_customer_description(StorageHelper.currentUser, this.subscriptionId, this.temporarySubscriptionName)
        .then((result) => {
          this.isEditable = false;
          this.messageBus.emit("refresh");
        })
        .catch((err) => {
          console.log(err);
          this.isEditable = false;
        });
    }
  }

  async cancelEditingCustomerDescription() {
    this.isEditable = false;
  }

  /** Dialog and tab interactivity **/
  changeActiveTab(tabName: string) {
    this.activeTab = tabName;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async openIncidentDialog(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.incident.emit("open");
  }

  openSelfServiceDialog(type: SelfserviceDialogType) {
    const roleCheckResult = this.auth.checkRoleAccess(this.subscription.product.type, "edit");
    if (!roleCheckResult.ok) {
      this.auth.roleEvent.emit(roleCheckResult);
      return;
    }

    if (!this.auth.isAuthenticatedForSelfService()) {
      const ssAuthData = { data: { state: false, initialAction: type, selfserviceState: { handler: "generic-subscription" } }};
      this.dialog.open(DialogAuthComponent, ssAuthData);
    } else {
      const ssDialogData = { type, subscription: this.subscription };

      // @ts-ignore FIXME this.subscription is not guaranteed to be of a type required by self-service dialog
      const ssDialogRef = openSelfServiceDialog(this.dialog, ssDialogData);
      ssDialogRef.componentInstance.close.subscribe((command) => {
        // console.log(`Received '${command}' through close pipe`);
        // evict the cache for the current subscription, to force a refresh.
        // this.apollo.client.cache.evict({ id: this.subscriptionCacheId }); // TODO RESTORE?
        this.querySubscription(this.query);
      });
    }
  }

  /** Other */
  private refresh = async () => {
    this.querySubscription(this.query);
    this.fetchActivities();
  }

  private querySubscription(query: Q) {
    const subscription = query
      .watch({ subscriptionId: this.subscriptionId, accessCustomerId: this.auth.viewingCustomerId })
      .valueChanges.subscribe((result) => {
        this.subscription = this.onQuerySuccess(result);

        const pendingAction = this.auth.getPendingStrongAction();
        const selfServiceState = this.auth.getSelfserviceState();
        if (pendingAction && selfServiceState && selfServiceState.handler === "generic-subscription") {
          this.auth.clearPendingStrongAction(pendingAction);
          this.openSelfServiceDialog(pendingAction);
        }
      });
    this.rxSubscriptions.push(subscription);
  }

  protected abstract onQuerySuccess(result: ApolloQueryResult<R>): S;
}

// TODO replace with a generated subscriptin
export interface MinimalSubscription {
  customerDescription: string;
  description: string;
  firewallEnabled?: boolean;
  status: string;
  product: {
    __typeName?: "ProductType";
    id: any;
    name: string;
    tag: string;
    type: string;
  };
}
